export const data = [
    {
      "id": 1,
      "name": "Google: Мои осу скины",
      "img": "src/images/osu_skins_logo.png",
      "url": "https://drive.google.com/drive/u/0/folders/1tgRMlWjcn5cf0uEVVvL1kPkEK5M1ZIcF"
    },
    {
      "id": 2,
      "name": "Telegram: Мой канал с осу картами",
      "img": "src/images/telegram_osumaps_logo.png",
      "url": "https://t.me/osucharts"
    },
    {
      "id": 3,
      "name": "Torrent: Тематический маппак",
      "img": "src/images/osu_maps_torrent_logo.png",
      "url": "https://rutracker.org/forum/viewtopic.php?t=6119071"
    },
    {
      "id": 4,
      "name": "Torrent: Маппак Талалы",
      "img": "src/images/osu_maps_talala.png",
      "url": "https://vk.com/wall-93941632_10259"
    },
    {
      "id": 5,
      "name": "Google: Альтернейт маппак",
      "img": "src/images/osu_maps_google_logo.png",
      "url": "https://drive.google.com/drive/folders/168PWup1XrHUUioH0bc3ePL4ziDWkITr0?usp=sharing"
    },
    {
      "id": 6,
      "name": "Donate: пожертвовать",
      "img": "src/images/donations.png",
      "url": "https://www.donationalerts.com/r/sed_god"
    }
  ]