const SUBJECT_MIN_LENGTH = 10;
const ALLOWED_EMAIL_DOMAINS = [
		'gmail.com', 'streamelements.com', 'twitch.tv', 'ppy.sh', 
		'github.com', 'odnoklassniki.ru', 'steampowered.com', 'amazonses.com',
		'discord.com', 'vk.com', 'yandex.ru', 'tabnine.com',
		'mlrmr.com'
	];

module.exports = {
	SUBJECT_MIN_LENGTH,
	ALLOWED_EMAIL_DOMAINS
}
