import { useContext, useEffect, useState } from "react"
import { ApiHostnameContext } from "./contexts/main";
import FileUploader from "./components_osu/FileUploader";

export default function AppOsu () {
	const {apiHostname} = useContext(ApiHostnameContext);
	const [ip, setIp] = useState('');

	useEffect(() => {
		if (!ip) {
			fetch(apiHostname+'/query?action=osu_ip')
				.then(response => response.json())
				.then(data => {
					setIp(data.ip);
				})
				.catch(error => console.log(error));
		}
    }, [ip]);

	if (ip) {
		return (
			<div>
				welcome to osu, {ip}, dear friend
				<FileUploader />
			</div>
		)
	} else {
		return (
            <div>
                Loading...
            </div>
        )
	}
}
