
import { useContext, useEffect, useState } from "react";
import { ALLOWED_EMAIL_DOMAINS } from "../consts/values";
import { PostContentContext } from "./MailContexts";

const timezone_offset = new Date().getTimezoneOffset()*60000;

export default function InboxItem ({ getInbox, opened, unique_key, addressee, from, subject, html, text, textAsHtml, date, addSpam, sendDelete, sendOpened }) {

	const { setContent } = useContext(PostContentContext);

	const [ canDelete, setCanDelete ] = useState(false);

	const is_allowed = ALLOWED_EMAIL_DOMAINS.filter( x => from.indexOf(x) > -1)?.length > 0 || false;

	const openPost = (new_content, id) => {
		setContent(new_content);
		sendOpened(id);
	}

	const check_time = () => {
		let local_date = new Date().getTime();
		let post_time = new Date(date.slice(0, -3)).getTime()-timezone_offset;

		if ( local_date-post_time > 90000){
			setCanDelete(true);
			console.log('can delete',local_date,post_time, local_date-post_time)
			if ( local_date-post_time < (90000 + 10000*2) ) {
				getInbox();
			}
		} else {
			setTimeout(check_time, 10000);
			console.log('not delete', local_date,post_time,local_date-post_time)
		}
	}

    useEffect( () => {
        check_time();
    }, [unique_key]);

    return (<div className='InboxItem'>
		<div className={ opened ? 'subject_opened' : 'subject' } title={from} onClick={() => openPost({ html, text, textAsHtml }, unique_key )}>{subject}</div>
		<div className='date' onClick={() => openPost({ html, text, textAsHtml }, unique_key )}>{date.slice(0, -3)}</div>
		{is_allowed ? <div className='add_to_spam'><button name="add_to_spam" disabled >Spam</button></div> :
			<div className='add_to_spam'><button name="add_to_spam" onClick={() => addSpam(unique_key, subject)}>Spam</button></div>}
		{ canDelete ? <div className='delete_post'><button name="delete_post" onClick={() => sendDelete(unique_key)}>Delete</button></div> :
			<div className='delete_post'><button name="delete_post" onClick={() => sendDelete(unique_key)} disabled>Delete</button></div>}
		
		</div>)

		

}
