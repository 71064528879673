
import { createContext,  useState } from 'react'

const ApiHostnameContext = createContext( null );

function ApiHostnameProvider({children}) {
	const [apiHostname, setApiHostname] = useState('https://api.svdgod.ru');
	const value = {apiHostname, setApiHostname};
	return (<ApiHostnameContext.Provider value={value}>{children}</ApiHostnameContext.Provider>);
}

export { 
	ApiHostnameContext,
    ApiHostnameProvider,
}