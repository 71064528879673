export const data = [
    {
        "id": 1,
        "name": "GitHub: ChervyachokMigo",
        "img": "src/images/github_logo.png",
        "url": "https://github.com/ChervyachokMigo?tab=repositories"
    },
    {
        "id": 2,
        "name": "Youtube: SVDG0D",
        "img": "src/images/youtube_logo.png",
        "url": "https://www.youtube.com/channel/UC0v-I8Iemr67p_MNUshCPmw"
    },{
        "id": 3,
        "name": "Discord: server sadgod",
        "img": "src/images/discord_logo.png",
        "url": "https://discord.gg/jq9y7atnBS"
    },
    {
        "id": 4,
        "name": "VK: svdg0d",
        "img": "src/images/vk_logo.png",
        "url": "https://vk.com/svdg0d"
    },
    {
        "id": 5,
        "name": "Telegram: SVDG0D",
        "img": "src/images/telegram_logo.png",
        "url": "https://t.me/SVDG0D"
    },
    {
        "id": 6,
        "name": "Twitch: sad_god_",
        "img": "src/images/twitch_logo.png",
        "url": "https://www.twitch.tv/sad_god_"
    },
    {
        "id": 7,
        "name": "Osu: SadGod",
        "img": "src/images/osu_logo.png",
        "url": "https://osu.ppy.sh/users/9547517"
    },
    {
        "id": 8,
        "name": "Steam: svdgod",
        "img": "src/images/steam_logo.png",
        "url": "https://steamcommunity.com/id/svdgod/"
    },
    {
        "id": 9,
        "name": "Shikimori: svdgod",
        "img": "src/images/shikimori_logo.png",
        "url": "https://shikimori.one/svdgod/list/anime?mylist=watching&order=rate_score"
    }
]
