import React, { useContext, useState } from 'react';
import { ApiHostnameContext } from '../contexts/main';

const Result = ({ status }) => {
	if (status === "success") {
		return <p>✅ File uploaded successfully!</p>;
	} else if (status === "fail") {
		return <p>❌ File upload failed!</p>;
	} else if (status === "uploading") {
		return <p>⏳ Uploading selected file...</p>;
	} else {
		return null;
	}
};

export default function FileUploader () {

	const {apiHostname} = useContext(ApiHostnameContext);
	const [file, setFile] = useState(null);
	const [status, setStatus] = useState('initial');

	const handleFileChange = (e) => {
		if (e.target.files) {
		setFile(e.target.files[0]);
		setStatus('initial');
		console.log(e.target.files);
		}
	};

	const handleUpload = async () => {
		if (file) {
			console.log('Uploading file...');
			setStatus('uploading');

			const formData = new FormData();
			formData.append('file', file);

			try {
			const result = await fetch( apiHostname+'/query?action=osu_upload', {
				method: 'POST',
				body: formData,
			});

			const data = await result.json();

			console.log(data);
			setStatus('success');
			} catch (error) {
			console.error(error);
			setStatus('fail');
			}
		}
	};

	return (
		<div>
		<div className="input-group">
			<label htmlFor="file" className="sr-only">
			Choose a file
			</label>
			<input id="file" type="file" onChange={handleFileChange} />
		</div>
		{file && (
			<section>
				
			File details:
			<ul>
				<li>Name: {file.name}</li>
				<li>Type: {file.type}</li>
				<li>Size: {file.size} bytes</li>
			</ul>
			</section>
		)}

		{file && <button onClick={handleUpload}>Upload a file</button>}

		<Result status={status} />

		</div>
	);
};