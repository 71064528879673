
import { useState, useEffect, useContext } from 'react'
import { isAuthedContext, TokenContext, SelectedPostContext, PostContentContext } from './MailContexts';
import { ApiHostnameContext } from '../contexts/main'
import Logout from './Logout';
import InboxItem from './InboxItem';
import { SUBJECT_MIN_LENGTH } from '../consts/values';

export default function Inbox (){
    const [ InboxItemsList, setInboxItemsList ] = useState([]);
    const { is_authed, setAuth } = useContext(isAuthedContext);
    const { token, setToken } = useContext(TokenContext);
	const { apiHostname } = useContext(ApiHostnameContext);
	const { content, setContent } = useContext(PostContentContext);

	const [ ignoreSubjectText, setIgnoreSubjectText ] = useState('');

	const [ inboxCount, setInboxCount ] = useState(0);

	const sendDelete = (unique_key) => {
        fetch([
            apiHostname+'/query?action=post_delete', 
            'unique_key=' + unique_key, 
            'token=' + token
        ].join('&')).then(response => response.json())
        .then(data => {
            console.log('deleted post', data);
			setContent({});
			getInbox();
        })
        .catch(error => console.error(error));
		
    }

	const sendOpened = (unique_key) => {
        fetch([
            apiHostname+'/query?action=post_open', 
            'unique_key=' + unique_key, 
            'token=' + token
        ].join('&')).then(response => response.json())
        .then(data => {
            console.log('opened post', data);
			getInbox();
        })
        .catch(error => console.error(error));
		
    }

    const addSpam = (unique_key, subject) => {
        fetch([
            apiHostname+'/query?action=add_spamer',
            'unique_key=' + unique_key, 
            'token=' + token
        ].join('&')).then(response => response.json())
        .then(data => {
            console.log('added to spam', data.email_name)
			setContent({});
			getInbox();
        })
        .catch(error => console.error(error));

		fetch([
				apiHostname+'/query?action=ignore_subject',
				'token=' + token,
				'subject=' + subject
			].join('&')).then(response => response.json())
			.then(data => {
				console.log('ignored subject', subject)
				getInbox();
			})
			.catch(error => console.error(error));
    }

	const ignore_subject = () => {
		if (ignoreSubjectText && ignoreSubjectText.length > SUBJECT_MIN_LENGTH){
			fetch([
				apiHostname+'/query?action=ignore_subject',
				'token=' + token,
				'subject=' + ignoreSubjectText
			].join('&')).then(response => response.json())
			.then(data => {
				console.log('ignored subject', ignoreSubjectText)
				setContent({});
				setIgnoreSubjectText('');
				getInbox();
			})
			.catch(error => console.error(error));
		}
	}

	const delete_subject = () => {
		if (ignoreSubjectText && ignoreSubjectText.length > SUBJECT_MIN_LENGTH){
			fetch([
				apiHostname+'/query?action=delete_subject',
				'token=' + token,
				'subject=' + ignoreSubjectText
			].join('&')).then(response => response.json())
			.then(data => {
				console.log('deleted subject', ignoreSubjectText)
				setContent({});
				setIgnoreSubjectText('');
				getInbox();
			})
			.catch(error => console.error(error));
		}
	}

	const getInbox = () => {
		if (token && is_authed){
			fetch([
				apiHostname+'/query?action=inbox',
				'token=' + token
			].join('&'))
				.then(response => response.json())
				.then(data => {
					if (data.error){
						console.log(data.error);
						setAuth(false);
						setToken('');
						setInboxItemsList([]);
						setInboxCount(0);
						return;
					}
					setInboxItemsList(data.inbox);
					setInboxCount(data.inbox.length);
				})
				.catch(error => console.log(error));
		}
	}

    useEffect(() => {
        getInbox();
    }, [ is_authed, token, inboxCount ]);

    if (is_authed){
            return (<div className='inbox-panel'>
                <div className='actions'>
                    <div className='inboxTitle'>Inbox</div>
                    <Logout /></div>
                <div className='inbox'>
                    { InboxItemsList && InboxItemsList.length > 0? 
                        InboxItemsList.map( (x) => {return <InboxItem 
							opened={x.opened}
							unique_key={x.unique_key} 
							className='addressee' 
							addressee={x.addressee}
							from={x.from}
							subject={x.subject}
							html={x.html}
							text={x.text}
							textAsHtml={x.textAsHtml}
							date={x.date}
							sendDelete={sendDelete}
							addSpam={addSpam}
							sendOpened={sendOpened}
							getInbox={getInbox}
						/>}):
                        <div>&lt; Пусто &gt;</div>}
                </div>
				<hr></hr>
				<div className='ignore_subjects'>
					Subject: 
					<input type="text" id='subject' minLength={ SUBJECT_MIN_LENGTH } value={ignoreSubjectText} onChange={ (e) => {
						if (ignoreSubjectText !== e.target.value) 
							setIgnoreSubjectText(e.target.value);
					}}></input>
					<button name="perform" onClick={ delete_subject }>Delete</button>
					<button name="perform" onClick={ ignore_subject }>Ignore</button>
				</div>
            </div>)
    } else {
        return (<div className='emptyDiv'></div>)
    }

}
