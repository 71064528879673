export const data = [
    {
      "id": 1,
      "text": "PackegerX"
    },
    {
      "id": 2,
      "text": "infarnoy"
    },
    {
      "id": 3,
      "text": "SadGod"
    },
    {
      "id": 4,
      "text": "CPU_1"
    },
    {
      "id": 5,
      "text": "topsherman"
    },
    {
      "id": 6,
      "text": "bluezenith88"
    },
    {
      "id": 7,
      "text": "zxcgodie"
    },
    {
      "id": 8,
      "text": "avepipa"
    },
    {
      "id": 9,
      "text": "becausebeacon"
    },
    {
      "id": 10,
      "text": "vsegdagotova"
    },
    {
      "id": 11,
      "text": "tester_pivka17"
    },
    {
      "id": 12,
      "text": "svdgod"
    }
  ]