import { AuthedProvider, TokenProvider, QueryProvider} from './components_mail/MailContexts'
import { ApiHostnameProvider } from './contexts/main'

import Mail from './Mail';

export default function AppMail () {

    return (
	<ApiHostnameProvider>
		<AuthedProvider>
		<TokenProvider>
			<QueryProvider>
				<Mail />
			</QueryProvider>
		</TokenProvider>
		</AuthedProvider>
	</ApiHostnameProvider>
    )
    
}
