
import { useContext, useEffect, useState } from 'react'
import { PostContentContext, isAuthedContext, SelectedPostContext, TokenContext } from './MailContexts';
import { ApiHostnameContext } from '../contexts/main'
import parse from 'html-react-parser';

const urlify = (text) => {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	return text.replace(urlRegex, (url) => '<a href="' + url + '">' + url + '</a>');
}

export default function PostContent () {

    const [ content_text, setContentText ] = useState('');

    const { is_authed } = useContext(isAuthedContext);
    const { content, setContent } = useContext(PostContentContext);

    const sanitize = (raw)=> {
        return raw.replace(/<!--[\s\S]*?--!?>/gm, '')
        .replace(/\s?<head[^>]*?>.*?<\/head>\s?/gm, '')
        .replace(/\s?<html[^>]*?>\s?/gm, '')
        .replace(/\s?<!DOCTYPE[^>]*?>\s?/gm, '')
        .replace(/\s?<\/html[^>]*?>\s?/gm, '')
        .replace('ahref', 'a href')
    }

    const changeContent = (type) => {
		if (type === 'close'){
			setContent({});
			return;
		}

		if (content[type] && content[type].length > 0) {
			let new_content = parse(sanitize(content[type]), {trim: true});
			setContentText(new_content);
		} else {
			console.error('post content is null')
		}
    }

    useEffect( () => {
        setContentText(content['html']?  parse(sanitize(content['html'])): content['text']);
    }, [ is_authed, changeContent, content, setContent]);

    if (is_authed && (content?.text || content?.html || content?.textAsHtml)) {
        return (
            <div className='post_area'>
                <div className='post_controls'>
					<button name="close" onClick={ () => changeContent('close')}>Close</button>
                    <button name="text" onClick={ () => changeContent('text')}>Text</button>
                    <button name="html" onClick={ () => changeContent('html')}>Html</button>
                    <button name="textashtml" onClick={ () => changeContent('textAsHtml')}>Text As Html</button>
                </div>
            <div className='post_content'> 
                {content_text}
            </div>
            </div>
        )
    } else {
        return (<div className='emptyDiv'></div>)
    }

}
